
import {RiFunctionLine, RiChat3Line, RiUserLine} from "react-icons/ri";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { IoAddCircleOutline } from "react-icons/io5";
const Sidebar = ({whichActive}) => {


    const [active, setActive] = useState({
        dashboard: "",
        profile: "",
        chatbots: "",
        createNew : ""
    });

    useEffect(() => {

        let tempActive = {  ... active };

        tempActive[whichActive] = "bg-gray-200";

        setActive(tempActive);

    },[whichActive]);

    return (

        <aside id="default-sidebar" className="z-40 w-full h-screen transition-transform -translate-x-full translate-x-0" aria-label="Sidebar">
            <div className="h-full px-3 py-4 overflow-y-auto bg-slate-100 dark:bg-gray-800">

                <div className="mb-3 ml-3 mt-3">
                 <span className="font-bold">Menu</span>
                </div>

                <ul className="space-y-2 font-medium">
                    <li>
                        <Link to="/chatbots" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-slate-200 dark:hover:bg-gray-700 ${active.chatbots}`}>
                            <RiChat3Line size={22} />
                            <span className="flex-1 ml-3 whitespace-nowrap">Chatbots</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/create-new" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-slate-200 dark:hover:bg-gray-700 ${active.createNew}`}>
                            <IoAddCircleOutline color="black" size={22} />
                            <span className="flex-1 ml-3 whitespace-nowrap">Create New</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/profile" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-slate-200 dark:hover:bg-gray-700 ${active.profile}`}>
                            <RiUserLine size={22} />
                            <span className="flex-1 ml-3 whitespace-nowrap">Profile</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/logout" className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-slate-200 dark:hover:bg-gray-700`}>
                            <RiLogoutCircleRLine size={22} />
                            <span className="flex-1 ml-3 whitespace-nowrap">Logout</span>
                        </Link>
                    </li>
                   
                </ul>
            </div>
        </aside>


    );

}

export default Sidebar;