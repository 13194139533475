import Main from "../Components/Main";
import { Input, H3, Button, BigProgress, BackBox } from "../Components/Basics";
import { useEffect, useRef, useState } from "react";
import { isEmpty } from "../Support/FormValidator";
import API from "../Support/API";

const Profile = () => {

    const formRef = useRef();

    const [errMsg, setErrMsg] = useState("");
    const [okMsg, setOkMsg] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isBtnLoading, setIsBtnLoading] = useState(false);

    useEffect(() => {

        let to;

        API.get('profile')
            .then((e) => {

                setIsLoading(false);

                to = setTimeout(() => {
                    const { name, email } = formRef.current;

                    name.value = e.profile.name;
                    email.value = e.profile.email;

                }, 100);
            });

        return () => to !== null ? clearTimeout(to) : ""

    }, []);

    const onUpdate = () => {

        setErrMsg("");
        setOkMsg("");

        if (isEmpty(["name"], formRef.current)) {
            setErrMsg("Please write the name");
            return;
        }

        const { name, password, newPassword, confirmPassword } = formRef.current;


        if (password.value !== "") {

            if (newPassword.value.length < 8) {
                setErrMsg("Password should be atleast 8 characeters long.");
                return;
            }

            if (newPassword.value !== confirmPassword.value) {
                setErrMsg("Confirm Password does not match");
                return;
            }

            if (name.value.length < 3) {
                setErrMsg("Name should be 3 characters long.");
                return;
            }
        }

        setIsBtnLoading(true);

        API.post("update-profile", {
            name: name.value,
            password: password.value,
            newPassword: newPassword.value,
            confirmPassword: confirmPassword.value
        })
            .then((e) => {

                setOkMsg("Updated");
                setIsBtnLoading(false);

                password.value = "";
                newPassword.value = "";
                confirmPassword.value = "";

            })
            .catch((err) => {
                setErrMsg(err.message);
                setIsBtnLoading(false);
            });
    }

    return (

        <Main yGap={false} active="profile">


            {

                isLoading ?


                    <BigProgress />


                    :

                    <div className="px-2 py-5 lg:py-5 lg:max-w-3xl lg:m-auto w-full">

                        <BackBox
                            msg={'Back To Chatbots'}
                            path={"/chatbots"}

                        />
                        <H3 className="mb-5">
                            Profile Settings
                        </H3>


                        <form ref={formRef} className="w-full">

                            <Input

                                type="text"
                                label="Your Email"
                                name="email"
                                disabled={true}

                            />

                            <Input

                                type="text"
                                label="Your Name"
                                name="name"

                            />

                            <h4 className="text-lg font-bold mb-3">Password (Optional)</h4>
                            <Input

                                type="password"
                                label="Current Password"
                                name="password"

                            />

                            <Input

                                type="password"
                                label="New Password"
                                name="newPassword"

                            />

                            <Input

                                type="password"
                                label="Retype New Password"
                                name="confirmPassword"

                            />

                            {
                                errMsg && <div className="my-4"><span className="text-red-600">{errMsg}</span></div>
                            }

                            {
                                okMsg && <div className="my-4"><span className="text-green-600">{okMsg}</span></div>
                            }


                            <Button onClick={onUpdate} isLoading={isBtnLoading} className={"mt-2 w-full"}>
                                Update
                            </Button>

                        </form>


                    </div>

            }


        </Main>

    );


}

export default Profile;