import Main from "../Components/Main";
import { Input, H3, Button, BackBox } from "../Components/Basics";
import { useRef, useState } from "react";
import API from "../Support/API";
import { useNavigate } from "react-router-dom";

const CreateNew = () => {

    const formRef = useRef();
    const [errMsg, setErrMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const onUpload = () => {

        setErrMsg("");

        //title
        if (formRef.current.title?.value === "") {
            setErrMsg("Please write title of the chatbot");
            return;
        }

        //file
        const file = formRef.current.doc.files[0];
        const file2 = formRef.current.doc2.files[0];
        const file3 = formRef.current.doc3.files[0];

        if (!file) {
            setErrMsg("No document selected");
            return;
        }

        // Check file extension
        const allowedExtensions = [".txt", ".pdf"];
        const fileExtension = file.name.split(".").pop();

        if (!allowedExtensions.includes(`.${fileExtension}`)) {
            setErrMsg("Document type is invalid");
            return;
        }

        //creating form data for axios
        const formData = new FormData();
        formData.append('doc', file);

        if (file2) formData.append('doc2', file2);
        if (file3) formData.append('doc3', file3);

        formData.append('title', formRef.current.title.value);

        setIsLoading(true);

        API.post("add-chatbot", formData)
            .then((e) => {
                navigate("/chatbots", { replace: true });
            })
            .catch((err) => {
                setErrMsg(err.message);
                setIsLoading(false);
            })

    }

    return (

        <Main yGap={false} active="createNew">


            <div className="px-2 py-5 lg:py-5 lg:max-w-3xl lg:m-auto w-full">


                <BackBox
                    msg={'Back To Chatbots'}
                    path={"/chatbots"}

                />
                <H3 className="mb-5">
                    Train A New Chatbot
                </H3>


                <form ref={formRef} className="w-full">

                    <Input

                        type="text"
                        label="Title Of Chatbot"
                        name="title"

                    />


                    <div className="mt-3">

                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Paper 1 (Required)</label>
                        <input accept=".txt, .pdf" name="doc" className="block w-full text-sm text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" type="file" />
                        <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">.txt, .pdf (Max Size: 5MB)</p>

                    </div>

                    <div className="mt-7">

                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Paper 2 (Optional)</label>
                        <input accept=".txt, .pdf" name="doc2" className="block w-full text-sm text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" type="file" />
                        <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">.txt, .pdf (Max Size: 5MB)</p>

                    </div>

                    <div className="mt-7">

                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Paper 3 (Optional)</label>
                        <input accept=".txt, .pdf" name="doc3" className="block w-full text-sm text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" type="file" />
                        <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">.txt, .pdf (Max Size: 5MB)</p>

                    </div>

                    {
                        errMsg && <div className="mt-4"><span className="text-red-600">{errMsg}</span></div>
                    }

                    <Button isLoading={isLoading} onClick={onUpload} className={"mt-6 w-full"}>
                        Train
                    </Button>

                </form>


            </div>


        </Main>

    );


}

export default CreateNew;