const Constants = {

    //without end slash /
    apiUrl: "https://api.scholaraid.store",

   // apiUrl: "http://localhost:8001",

    appName: "ScholarAID",

    trainingNotice: "This chatbot is currently on training, please try it later.",

    initPrompts : [
        "Tell me the key points in bullets",
        "Summarise the document",
        "What is [YOUR TOPIC] ?",
    ],


}

export default Constants;