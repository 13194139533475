function markdownToHTMLTable(markdownTable) {
    
    const rows = markdownTable.trim().split('\n');
    const headers = rows[0].split('|').slice(1, -1).map(header => header.trim());

    let html = '<table class="border-collapse border border-gray-300">';

    // Generate table header
    html += '<thead class="bg-gray-200">';
    html += '<tr class="border border-gray-300">';
    headers.forEach(header => {
        html += `<th class="border border-gray-300 p-2">${header}</th>`;
    });
    html += '</tr>';
    html += '</thead>';

    // Generate table body
    html += '<tbody>';
    for (let i = 2; i < rows.length; i++) {
        const cells = rows[i].split('|').slice(1, -1).map(cell => cell.trim());
        html += '<tr class="border border-gray-300">';
        cells.forEach(cell => {
            html += `<td class="border border-gray-300 p-2">${cell}</td>`;
        });
        html += '</tr>';
    }
    html += '</tbody>';

    html += '</table>';

    return html;
}

export default markdownToHTMLTable;