import NavBar from "./NavBar";
import Sidebar from "./Sidebar";


const Main = ({children, active, yGap}) => {

    return (

        <div className="">

                <div className="flex flex-wrap">

                    <div id="sideMenu" className="fixed -top-2 lg:top-0 left-0 flex-col xl:w-[16%] xl:flex hidden w-8/12">

                       <Sidebar whichActive={active} />

                    </div>

                    <div className="flex-col xl:w-[16%] sm:w-0">

        
                    </div>

                    <div className="flex flex-col w-full xl:w-[84%]">
                       <NavBar />
                       <div className={"px-2 lg:px-5 lg:mt-0" + (yGap === undefined ? "sm:py-1" : '')}>
                         {children}
                       </div>
                    </div>

                </div>

         </div>

    )

}

export default Main;