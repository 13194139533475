import Main from "../Components/Main";
import userImage from "../Images/user.png";
import aiImage from "../Images/ai.png";
import { useEffect, useRef, useState } from "react";
import API from "../Support/API";
import { BigProgress } from "../Components/Basics";
import { useNavigate, useParams } from "react-router-dom";
import typingImage from "../Images/typing.gif";
import markdownToHTMLTable from "../Support/MarkDownTable";
import Constants from "../Support/Constants";

const DefaultAIMessage = ({ addToInput, title }) => {

    return (

        <div className="flex justify-start my-2">

            <div className="flex-shrink-0 mr-3">
                <img src={aiImage} width={30} />
            </div>

            <div className="bg-blue-600 text-white xl:w-[65%] w-[90%] p-5 rounded-2xl whitespace-pre-line">
                <div> Hi, Start chatting with your chatbot titled as <span className="font-bold">{title}</span>. Here are some prompts that you can try :</div>
                <br></br>

                <ul className="max-w-md space-y-1 font-bold list-disc list-inside dark:text-gray-400">

                    {
                        Constants.initPrompts.map((e) => {
                            return (
                                <li className="cursor-pointer" onClick={addToInput}>
                                    {e}
                                </li>
                            );
                        })
                    }


                </ul>

            </div>

        </div>

    );
}

const Chat = () => {

    const [msgs, setMsgs] = useState([]);
    const pg = useRef(1);
    const allowPg = useRef(false);

    const inputRef = useRef();

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const { id } = useParams();
    const alreadyStarted = useRef(false);
    const allowSend = useRef(true);

    const [title, setTitle] = useState("");

    const chatRef = useRef();

    useEffect(() => {

        (async function () {

            if (alreadyStarted.current) {
                return;
            }

            alreadyStarted.current = true;
            let api;

            try {

                api = await API.get("chat/" + id);
                setTitle(api.title);

                api = await API.get(`messages/${id}/${pg.current}`);

                if (api.messages.length > 0) {
                    setMsgs(api.messages);
                    pg.current = pg.current + 1;
                    //allow pagination
                    allowPg.current = true;
                }
                else {
                    allowPg.current = false
                }

                setIsLoading(false);

                setTimeout(() => {
                    chatRef.current.scrollTo({
                        top: chatRef.current.scrollHeight
                    });
                }, 50);

            }
            catch (err) {

                navigate("/chatbots", { replace: true });
            }

        })();

    }, [id]);


    //scroll top event
    const onChatScroll = async (e) => {

        //as per my testing, chatRef.current is undefined in useEffect and react takes time to attched ref to the element
        if (allowPg.current === false || chatRef.current === undefined) {
            return;
        }

        const buffer = 70;
        const top = e.target.scrollTop - buffer;
        let api;

        if (top <= 0) {

            allowPg.current = false;

            try {

                api = await API.get(`messages/${id}/${pg.current}`);

                if (api.messages.length > 0) {

                    let newMsgs = api.messages;

                    setMsgs((msgs) => [...newMsgs, ...msgs]);
                    pg.current = pg.current + 1;
                    allowPg.current = true;
                }

            }
            catch (err) { }

        }

    }

    const addNewUserMsg = () => {

        if (!allowSend.current) {
            return;
        }

        allowSend.current = false;

        const text = inputRef.current.value;

        inputRef.current.value = "";

        if (text === "") {
            return;
        }

        const msgObj = [
            {
                type: "user",
                msg: text
            },
            {
                type: "typing",
                msg: ""
            }
        ];

        setMsgs((msgs) => {
            return [...msgs, ...msgObj]
        });

        setTimeout(() => {
            chatRef.current.scrollTo({
                top: chatRef.current.scrollHeight,
                behavior: "smooth",
            });
        }, 200);

        //api to send message
        API.post("chat/" + id, {
            message: text
        })
            .then((e) => {
                addNewAIMsg(e.reply);
                allowSend.current = true;
            })
            .catch((err) => {
                addNewAIMsg("Sorry, I am down right now. Please try again later.");
                allowSend.current = true;
            });

    }

    const addNewAIMsg = (text) => {

        if (text === "") {
            return;
        }

        const msgObj = {
            type: "ai",
            msg: text
        }

       // text = text.replace(/\n{2,}/g, '\n');

        setMsgs((msgs) => {

            let filterMsgs = msgs.filter((e) => e.type != 'typing');

            return [...filterMsgs, msgObj]
        });

        setTimeout(() => {
            chatRef.current.scrollTo({
                top: chatRef.current.scrollHeight,
                behavior: "smooth",
            });
        }, 200);
    }

    const addToInput = (e) => {
        inputRef.current.value = e.target.innerText

    }

    const containsTable = (markdown) => {
        const tableRegex = /^\s*\|.*\|\s*$/gm;
        return tableRegex.test(markdown);
    }


    const formatMsg = (msg) => {

        if (msg === null) return "4000 tokens limit exceeded";

        let newMsg;

        msg = msg.replace(/\n\n+/g, '\n');

        if (containsTable(msg)) {

            newMsg = markdownToHTMLTable(msg);
        }
        else {
            newMsg = msg.replace(/\[(.*?)\]\.?/g, '\n\n<span class="font-bold">$1</span>\n\n');
        }

        return newMsg;
    }


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          addNewUserMsg();
          event.preventDefault();
        }
      };

    return (

        <Main active={'chatbots'} yGap={false}>

            {

                isLoading ?

                    <BigProgress />

                    :

                    <div className="w-full m-auto px-2 max-w-6xl">

                        <div className="bg-slate-100 py-[3px] w-full my-2 rounded-3xl px-1 sm:px-0">
                            <div className="text-sm text-center">You are chatting with <span className="font-medium">{title.length > 25 ? title.substring(0, 25) + '...' : title}</span></div>
                        </div>

                        <div onScroll={onChatScroll} ref={chatRef} className="flex items-between flex-col chat-height px-3 hide-scrollbar">

                            <DefaultAIMessage addToInput={addToInput} title={title} />

                            {

                                msgs.map((e) => {

                                    return (

                                        e.type === 'user' ?

                                            <div key={e.id} className="my-2 mt-14 flex justify-end">

                                                <div className="bg-slate-200 text-black xl:w-[65%] w-[90%] p-5 rounded-2xl whitespace-pre-line">
                                                    {e.msg}
                                                </div>

                                                <div className="ml-3 flex-shrink-0">
                                                    <img src={userImage} width={30} />
                                                </div>

                                            </div>

                                            :


                                            e.type === 'typing'

                                                ?

                                                <div key={'typing'} className="flex justify-start my-2">

                                                    <div className="flex-shrink-0 mr-3">
                                                        <img src={aiImage} width={30} />
                                                    </div>

                                                    <div className="bg-blue-600 text-white xl:w-[65%] w-[90%] p-5 rounded-2xl whitespace-pre-line">
                                                        <img src={typingImage} width={35} />
                                                    </div>

                                                </div>



                                                :

                                                <div key={e.id} className="flex justify-start my-2">

                                                    <div className="flex-shrink-0 mr-3">
                                                        <img src={aiImage} width={30} />
                                                    </div>

                                                    <div className="bg-blue-600 text-white xl:w-[65%] w-[90%] p-5 rounded-2xl whitespace-pre-line" dangerouslySetInnerHTML={{ __html: formatMsg(e.msg) }} />

                                                </div>

                                    );

                                })
                            }



                        </div>

                        <label for="chat" className="sr-only">Your message</label>
                        <div className="bg-slate-100 dark:bg-slate-700 rounded-2xl flex flex-col justify-center mb-2">

                            {/* <div className="flex items-center pl-7 pt-5 pb-2">
                                <p className="font-bold">{title}</p>
                            </div> */}

                            <div className="flex items-center px-3 hide-scrollbar">
                                <textarea onKeyDown={handleKeyDown} ref={inputRef} rows="2" className="hide-scrollbar resize-none pt-4 block mx-4 p-2.5 w-full text-gray-900 bg-slate-100 rounded-lg outline:none focus:shadow-none focus:ring-0 border-none focus:border-none dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your message..."></textarea>
                                <button onClick={addNewUserMsg} type="submit" className="inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600">
                                    <svg aria-hidden="true" className="w-6 h-6 rotate-90" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path></svg>
                                    <span className="sr-only">Send message</span>
                                </button>
                            </div>
                        </div>



                    </div>

            }

        </Main>

    );

}

export default Chat;