import Main from "../Components/Main";
import { Button, Link, SmallButton, H3, BigProgress, APIProgress } from "../Components/Basics";
import { DefaultBadge, GreenBadge, RedBadge, YellowBadge } from "../Components/Badges";
import { RiDeleteBin7Fill, RiChat1Fill, RiAddCircleFill } from "react-icons/ri";
import { useEffect, useRef, useState } from "react";
import API from "../Support/API";
import DeleteModal from "../Components/Modals/DeleteModal";
import NoticeModal from "../Components/Modals/NoticeModal";
import dateFormatter from "dateformat";

import { TbMoodEmpty } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import Constants from "../Support/Constants";

const ChatBots = () => {

    const [pg, setPg] = useState(1);
    const allowPg = useRef(true);

    const [data, setData] = useState(null);

    //modals
    const [showModal, setShowModal] = useState(0);
    const [dltId, setDltId] = useState(null);

    const [notice, setNotice] = useState({
        msg: "",
        title: ""
    });

    //ontraining and timeout
    const onTraining = useRef();
    const timeOut = useRef();

    const navigate = useNavigate();

    const trainingNotice = {
        msg: Constants.trainingNotice,
        title: "Chatbot Is On Training"
    };

    const failedNotice = {
        msg: "The training of this chatbot is failed due to unknown reason. Please try creating a new chatbot or contact support.",
        title: "Chatbot Training Failed"
    };

    const [showNoticeModal, setShowNoticeModal] = useState(0);

    const dataLoadedOnce = useRef(false);

    useEffect(() => {

        if (allowPg.current === false) {
            return;
        }

        allowPg.current = false;

        API.get("chatbots" + "/" + pg)
            .then((e) => {

                if (e.chatbots.length === 0) {
                    if(!dataLoadedOnce.current) setData([]);
                    allowPg.current = false;
                    return;
                }

                if(!dataLoadedOnce.current) dataLoadedOnce.current = true;

                const chatbots = e.chatbots;

                if (data === null) {

                    setData(chatbots);

                    let trainingChatbots = chatbots.filter((e) => e.status === 'training');
                    onTraining.current = trainingChatbots.length;

                }
                else {
                    setData((data) => {
                        return [...data, ...chatbots]
                    });
                   
                }

                allowPg.current = true;

            })
            .catch((err) => {
                console.clear();
            })

    }, [pg]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    const KNumber = (number) => {

        if (number >= 1000) {
            return (number / 1000).toFixed(1) + "K";
        }
        else {

            return number;

        }
    }

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

            // Check if the user has scrolled to the bottom (with a little buffer)
            const buffer = 150; // Adjust this value as needed
            const isAtBottom = documentHeight - (windowHeight + scrollTop) <= buffer;


            if (isAtBottom) {

                setPg((pg) => {
                    if (allowPg.current) {
                        return pg + 1;
                    }
                    else {
                        return pg;
                    }
                });
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    //const [onTraining, setOnTraining] = useState(0);


    const [updateStatus, setUpdateStatus] = useState(0);

    //UPDATE TRAINING STATUS OF CHATBOTS

    useEffect(() => {

        let interval = 15000; //15 sec

        //interval
        timeOut.current = setInterval(() => {

            setUpdateStatus((e) => e + 1);

        }, interval);

        return () => {

            if (timeOut.current !== null) {
                clearInterval(timeOut.current);
            }
        }

    }, []);

    //update status by calling api
    useEffect(() => {

        if (onTraining.current > 0) {

            const sliceValue = onTraining.current;

            API.get("training-chatbots" + "?max=" + onTraining.current)
                .then((e) => {

                    const chatbots = e.chatbots;

                    //add new then add old after slicing new
                    setData((d) => [...chatbots, ...d.slice(sliceValue)]);

                    //get length of on training chatbots
                    onTraining.current = chatbots.filter((e) => e.status === 'training').length;
                })
                .catch((err) => {
                    console.clear()
                });
        }

    }, [updateStatus]);

    const dltChatBot = (id) => {

        setDltId(id);
        setShowModal((showModal) => {
            return showModal + 1;
        });

    }

    const removeElement = (id) => {

        setData((data) => {

            return data.filter((e) => e.key !== id);

        });
    };

    const showNotice = (status) => {

        if (status === 'training') {
            setNotice(trainingNotice);
        }
        else if (status === "failed") {
            setNotice(failedNotice);
        }
        else {
            return;
        }

        setShowNoticeModal((e) => e + 1);

    }

    const dateFormat = (date) => {

        const now = new Date(date);

        const newDate = dateFormatter(now, "mmmm dd, yyyy");

        return newDate;
    }


    return (

        <>

            <DeleteModal id={dltId} show={showModal} removeFunc={removeElement} />
            <NoticeModal show={showNoticeModal} notice={notice} />

            <Main yGap={false} active="chatbots">

                <div className="p-2">

                    <div className="flex items-center justify-between mb-5">

                        <H3>
                            ChatBots
                        </H3>

                        <Link to={"/create-new"}>
                            <SmallButton>
                                Create New
                            </SmallButton>
                        </Link>


                    </div>


                    {

                        data === null ?

                            <APIProgress />

                            :

                            data !== null && data.length === 0 ?

                                <div className="c-height w-full items-center flex justify-center flex-col">

                                    <TbMoodEmpty className="my-3 text-blue-600" size={50} />

                                    <p class="text-base text-gray-900 dark:text-white text-center">You do not have any chatbots, create now by clicking on "Create New" button.</p>


                                </div>

                                :


                                <div className="relative overflow-x-auto">

                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">

                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Chatbot Title
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Status
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Words Used
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Updated At
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {

                                                data !== null && data.map((e) => {

                                                    //if status is training, increase on training value

                                                    return (
                                                        <tr key={e.key} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                {e.title}
                                                            </th>
                                                            <td className="px-6 py-4">
                                                                {
                                                                    e.status === 'active' ?

                                                                        <GreenBadge text={capitalizeFirstLetter(e.status)} />

                                                                        :

                                                                        e.status === 'failed' ?

                                                                            <RedBadge text={capitalizeFirstLetter(e.status)} />

                                                                            :

                                                                            <DefaultBadge text={capitalizeFirstLetter(e.status)} />
                                                                }
                                                            </td>

                                                            <td className="px-6 py-4">
                                                                <YellowBadge text={KNumber(e.words)} />
                                                            </td>

                                                            <td className="px-6 py-4">
                                                                {dateFormat(e.updatedAt)}
                                                            </td>

                                                            <td className="px-6 py-4">
                                                                <div className="flex items-center">

                                                                    {

                                                                        e.status !== 'active' ?

                                                                            <RiChat1Fill
                                                                                size={20}
                                                                                onClick={() => {
                                                                                    showNotice(e.status);
                                                                                }}
                                                                                className="text-black dark:text-white mr-2"
                                                                            />

                                                                            :

                                                                            <Link to={"/chat/" + e.key}>
                                                                                <RiChat1Fill size={20} className="text-black dark:text-white mr-2" />
                                                                            </Link>



                                                                    }

                                                                    <RiDeleteBin7Fill onClick={() => dltChatBot(e.key)} size={20} color="red" />

                                                                </div>
                                                            </td>

                                                        </tr>
                                                    )

                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>

                    }


                </div>

            </Main>

        </>

    );

}

export default ChatBots;